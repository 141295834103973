import React from 'react'


export default class Test extends React.Component {

    constructor(props) {
        super(props);

        this.showAnimation = this.showAnimation.bind(this);

    }
    
    MAX_ANIMATIONS = 2;
    currentAnimation = 0;

    componentDidUpdate(prevProps) {
        this.showAnimation();
    }

    showAnimation ()  {
        debugger;
            let classString = 'animation-' + this.currentAnimation;
            let items = document.getElementsByClassName(classString);
            for (let i = 0; i < items.length; i++) {
                items[i].beginElement();
            }

            this.currentAnimation++;
            if (this.currentAnimation === this.MAX_ANIMATIONS) {
                this.currentAnimation = 0;
            }

    }
    render() {
        return (
            <div style={{ backgroundColor: 'blue', minHeight: '100vh' }}>
            <button onClick={this.showAnimation}>click me</button>
                <div style={{ width: '700px', paddingLeft: '5%', paddingTop: '10%' }}>
           
<svg width="960px" height="1080px" viewBox="0 0 960 1080" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>1</title>
    <defs>
        <pattern id="pattern-1" width="97.0761589" height="91.008899" x="406.923841" y="189.991101" patternUnits="userSpaceOnUse">
            <use xlinkHref="#image-2" transform="scale(1.51681498,1.51681498)"></use>
        </pattern>
        <image id="image-2" width="64" height="60" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAB4CAYAAAA6//q/AAAABGdBTUEAALGN5fIAKQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAAICgAwAEAAAAAQAAAHgAAAAAWuD27QAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAA3hJREFUeAHt2kFu1DAUBuCk9AQgIYFYljVXmBOAxGE4BBdhh8QNegXWsEQgkOAERSGeYjvTzjxVkarK8ddNnNrOJJ+fs8k//nx9MQ3//8Zx/PH005fn+fzY8debl9+naXp2rM/89vzOlgs5TcPl8vxYOxoT9eVrRWOiPvOvBSKjqO+UXy2Acfxzfj69ywNPHfdj5rG3+s1v0u8svbaHYfxw/mh69eTj12+3FvbGP9KYNDbNSXPNb9vvxvI6JUCAAAECBAgQIECAAAECBAgQIECAAAECBDYmMF5/3x8u01e+u3wMSs//++3Fi6ur8f04Drt0nj5Dmt+m31gCIelz7h2+CO4X/+/4eV71x2nxy5/5TfrVPMC8oGlXlwU90diPubn4aaz5TfrVApjXML/ST6z9/t/RmKgvXzMaE/WZfy0QGUV9p/wOCiAPcuxH4KAA1mTKllTmt5eprAUg09dkpq9swJXrJxPYeSayFJAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsEkBmcB5WXvONMoE5n3daaax5gFk+prM9OX6XZvJrAUwX2lNpqzcgPlN+h0UwHIxtfsQOCgAmb72Mn3LMl2zfrUAVmbKyg2Y32SmUCZQJrDsYQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygTKBw668EU40IqOoL18uGhP13df8+gbIv+DYlcBBAazJlC21zG8vU1gLQKavyUxf2YAr108mUCaw1JAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsD0BmcB5TWUCU2F3mokre7rT5695AJlAmcCHyKSVHbh/CQ275fmxdnSPUV++VjQm6tvq/PoGyE/o2JXAQQHI9LWX6VtW65r1qwWwMlNWbsD8JjOFMoEygWUPaxAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQLbE5AJnNdUJjAVdqeZuLKnO33+mgeQCZQJ7DETV94A+5fgsFueH2tHRlFfvlY0Juq7r/n1DZB/wbErgYMCWJMpW2qZ316msBaATF+Tmb6yAVeun0ygTGCpIQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygQ+RSSs7cP8SGnbL82Pt6B6jvnytaEzUt9X5/wDyWjn47L/AWAAAAABJRU5ErkJggg=="></image>
        <pattern id="pattern-3" width="97.0761589" height="91.008899" x="233.923841" y="627.991101" patternUnits="userSpaceOnUse">
            <use xlinkHref="#image-4" transform="scale(1.51681498,1.51681498)"></use>
        </pattern>
        <image id="image-4" width="64" height="60" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAB4CAYAAAA6//q/AAAABGdBTUEAALGN5fIAKQAAAGxlWElmTU0AKgAAAAgABAEaAAUAAAABAAAAPgEbAAUAAAABAAAARgEoAAMAAAABAAIAAIdpAAQAAAABAAAATgAAAAAAAACQAAAAAQAAAJAAAAABAAKgAgAEAAAAAQAAAICgAwAEAAAAAQAAAHgAAAAAWuD27QAAAAlwSFlzAAAWJQAAFiUBSVIk8AAAA3hJREFUeAHt2kFu1DAUBuCk9AQgIYFYljVXmBOAxGE4BBdhh8QNegXWsEQgkOAERSGeYjvTzjxVkarK8ddNnNrOJJ+fs8k//nx9MQ3//8Zx/PH005fn+fzY8debl9+naXp2rM/89vzOlgs5TcPl8vxYOxoT9eVrRWOiPvOvBSKjqO+UXy2Acfxzfj69ywNPHfdj5rG3+s1v0u8svbaHYfxw/mh69eTj12+3FvbGP9KYNDbNSXPNb9vvxvI6JUCAAAECBAgQIECAAAECBAgQIECAAAECBDYmMF5/3x8u01e+u3wMSs//++3Fi6ur8f04Drt0nj5Dmt+m31gCIelz7h2+CO4X/+/4eV71x2nxy5/5TfrVPMC8oGlXlwU90diPubn4aaz5TfrVApjXML/ST6z9/t/RmKgvXzMaE/WZfy0QGUV9p/wOCiAPcuxH4KAA1mTKllTmt5eprAUg09dkpq9swJXrJxPYeSayFJAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsEkBmcB5WXvONMoE5n3daaax5gFk+prM9OX6XZvJrAUwX2lNpqzcgPlN+h0UwHIxtfsQOCgAmb72Mn3LMl2zfrUAVmbKyg2Y32SmUCZQJrDsYQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygTKBw668EU40IqOoL18uGhP13df8+gbIv+DYlcBBAazJlC21zG8vU1gLQKavyUxf2YAr108mUCaw1JAGAQIECBAgQIAAAQIECBAgQIAAAQIECBAgsD0BmcB5TWUCU2F3mokre7rT5695AJlAmcCHyKSVHbh/CQ275fmxdnSPUV++VjQm6tvq/PoGyE/o2JXAQQHI9LWX6VtW65r1qwWwMlNWbsD8JjOFMoEygWUPaxAgQIAAAQIECBAgQIAAAQIECBAgQIAAAQLbE5AJnNdUJjAVdqeZuLKnO33+mgeQCZQJ7DETV94A+5fgsFueH2tHRlFfvlY0Juq7r/n1DZB/wbErgYMCWJMpW2qZ316msBaATF+Tmb6yAVeun0ygTGCpIQ0CBAgQIECAAAECBAgQIECAAAECBAgQIEBgewIygfOaygSmwu40E1f2dKfPX/MAMoEygQ+RSSs7cP8SGnbL82Pt6B6jvnytaEzUt9X5/wDyWjn47L/AWAAAAABJRU5ErkJggg=="></image>
    </defs>
    <g id="1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-2561" transform="translate(85.000000, 197.000000)">
            <path d="M240.8574,643.7167 C180.0724,727.7047 108.9594,627.0007 51.3524,573.4817 C-6.2446,519.9627 -34.4966,386.5377 71.1944,342.8627 C143.8504,312.8387 182.8814,355.5387 240.4774,409.0587 C298.0844,462.5767 295.7504,567.8827 240.8574,643.7167" id="Fill-1-GF1" fill="#FFD11B"></path>
            <path d="M501.1768,109.9902 C578.8998,36.3722 608.7468,138.1842 659.0968,191.6722 C790.3528,331.0722 465.8228,494.8032 488.8978,410.2292 C511.9618,325.6592 423.6348,323.4522 341.0878,352.5672 C200.8658,402.0242 169.1168,164.0302 325.8298,160.6452 C410.2518,158.8222 423.4438,183.6112 501.1768,109.9902" id="Fill-3-GF1" fill="#FFFFFF"></path>
            <path d="M375.5059,46.582 C404.5629,7.063 465.9669,14.249 501.8469,33.303 C537.7159,52.36 485.3189,93.048 459.7409,121.081 C441.3829,141.199 412.8699,152.106 395.1819,136.801 C377.4939,121.497 346.4489,86.1 375.5059,46.582" id="Fill-5-GF1" fill="#C672E6"></path>
            <path d="M688.5947,109.4189 C678.0807,145.6399 580.6577,197.2419 544.5747,186.7509 C508.5027,176.2549 515.7227,104.3009 526.2367,68.0789 C536.7607,31.8549 574.3977,-8.8121 610.4807,1.6799 C646.5637,12.1709 699.1077,73.1969 688.5947,109.4189" id="Fill-2168-GF1" fill="#FFD11B"></path>
            <path d="M208.1592,622.287 C226.7682,482.078 379.2632,445.333 491.2952,544.153 C560.5542,624.433 493.9332,680.354 421.0372,671.38 C320.6732,659.024 408.3592,747.395 343.9152,803.883 C292.8132,848.658 305.3432,765.344 244.0792,741.061 C198.9992,723.198 202.5382,670.416 208.1592,622.287" id="Fill-2170-GF1" fill="#FFFFFF"></path>
            <path d="M586.2085,710.9716 C600.3455,742.9366 567.5915,809.0026 510.2005,809.4556 C452.8185,809.9096 401.7875,750.5526 387.6505,718.5876 C373.5035,686.6226 426.5105,722.6106 474.5835,695.7346 C522.6565,668.8606 572.0615,679.0056 586.2085,710.9716" id="Fill-2172-GF1" fill="#E94E24"></path>
            <path d="M267.7773,424.1786 C312.7233,395.9066 368.7763,421.1776 396.9193,466.3286 C568.0243,726.3596 96.3213,550.3606 267.7773,424.1786" id="Fill-2174-GF1" fill="#E3EEF2"></path>
            <path d="M239.0083,675.3827 C251.5023,667.6207 267.0843,674.5587 274.9063,686.9527 C322.4463,758.4247 191.3173,709.9027 239.0083,675.3827" id="Fill-2176-GF1" fill="#FFD11B"></path>
        </g>
        <path d="M618.679111,344.485266 C651.633377,291.264268 687.071951,271.505459 724.994831,285.208839 C781.879152,305.763911 812.106767,334.486873 812.106767,367.994177 C812.106767,401.501481 791.925717,415.31171 791.925717,443.376305 C791.925717,471.440901 810.09813,503.112895 834.671805,518.629859 C859.24548,534.146823 911.720268,605.549895 825.044551,595.845872 C738.368835,586.141848 614.218856,637 580.700999,637 C547.183142,637 474.16886,561.269366 517.219287,518.629859 C560.269714,475.990351 566.650687,472.420705 580.700999,443.376305 C590.067874,424.013372 602.727244,391.049692 618.679111,344.485266 Z" id="Path-2-GF1" fill="url(#pattern-1)"></path>
        <path d="M438.628702,1014 C502.15517,1014 530,864.030593 530,821.262503 C530,778.494414 489.431731,719 425.905263,719 C367.095764,719 337.015626,734.285099 331.207063,775.853603 C330.741172,779.187712 331.207063,844.194876 331.207063,847.370493 C331.207063,890.138582 375.102235,1014 438.628702,1014 Z" id="Oval-GF1" fill="url(#pattern-3)"></path>
    </g>
</svg>



                </div>

            </div>
        )
    }
}
